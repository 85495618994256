




























































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import RepositoryFactory from '@/api/RepositoryFactory';
import { IFolder } from '@/models/gallery.models';
import ApiService from '@/api/api.services';
import moment from 'moment';
import { MIMETYPE_ICON_MAP } from '@/utils/mimetype.icons';

const fileRepo = RepositoryFactory.get('file');

@Component({
    name: 'gallery-modal',
})
export default class GalleryModal extends Vue {
    private isLoading = false;

    private base_url = process.env.VUE_APP_API_URL;

    private folder: IFolder | null = null;

    @PropSync('openModal', { type: Boolean }) syncedOpenModal!: boolean;

    @Prop(String) folder_id!: string;
    @Prop(String) subtitle?: string;

    created() {
        if (!this.folder_id) {
            console.error('Folder ID not supplied!');
            this.isLoading = true;
            return;
        }
        this.getFolder();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // const gallery = (this.$refs.pictureSwipe as any).pswp;
        // gallery.listen('gettingData', function (index: number, item: any) {
        //     if (item && ((item.w ?? 0) < 1 || (item.h ?? 0) < 1)) {
        //         // unknown size
        //         const img = new Image();
        //         img.onload = function (): void {
        //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //             item.w = (this as any).width; // set image width
        //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //             item.h = (this as any).height; // set image height
        //             gallery.invalidateCurrItems(); // reinit Items
        //             gallery.updateSize(true); // reinit Items
        //         };
        //         img.src = item.src ?? '#'; // let's download image
        //     }
        // });
    }

    private async getFolder(): Promise<void> {
        try {
            this.isLoading = true;
            this.folder = await fileRepo.getFolder(this.folder_id);
            this.folder?.files?.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private get folderItems() {
        const token = this.getToken();
        const auth = this.getAuthToken();
        return this.folder?.files
            ?.filter((f) => f.mimetype.includes('image'))
            ?.map((f) => {
                const src = `${this.base_url}/crm/file/${f.id}/download?auth=${auth}&token=${token}`;
                let thumbnail = `${this.base_url}/crm/file/${f.id}/thumbnail?auth=${auth}&token=${token}`;
                if (!f.mimetype.startsWith('image')) {
                    thumbnail = MIMETYPE_ICON_MAP[f.mimetype] ?? '#';
                }
                return {
                    src,
                    thumbnail,
                    w: 0,
                    h: 0,
                    alt: f.name,
                    title: f.name,
                    published: true,
                    origin: f,
                };
            });
    }

    private uploadFiles() {
        document.getElementById('fileUpload')?.click();
    }

    private async uploadSelectedFiles(input: any) {
        const formData: FormData = new FormData();
        input.target.files.forEach((file: File) => {
            formData.append(file.name, file);
        });
        try {
            this.isLoading = true;
            await fileRepo.addFiles(this.folder_id, formData);
            this.$toasted.success('Úspěšně nahráno', {
                fullWidth: true,
                action: [],
                fitToScreen: true,
                closeOnSwipe: true,
                duration: 2000,
            });
            this.getFolder();
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    // private openGallery(files: any[], index: number) {
    //     const pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;

    //     const items = files.map((f) => ({
    //         src: `${this.base_url}/file/${f.id}/download?auth=${this.getAuthToken()}&token=${this.getToken()}`,
    //         title: f.name,
    //         w: 0,
    //         h: 0,
    //     }));

    //     const options = {
    //         index: index,
    //     };

    //     const gallery = new PhotoSwipe(pswpElement, new UIFramework(), items, options);
    //     gallery.listen('gettingData', function (index, item) {
    //         if (item && ((item.w ?? 0) < 1 || (item.h ?? 0) < 1)) {
    //             // unknown size
    //             const img = new Image();
    //             img.onload = function (): void {
    //                 // will get size after load
    //                 item.w = (this as any).width; // set image width
    //                 item.h = (this as any).height; // set image height
    //                 gallery.invalidateCurrItems(); // reinit Items
    //                 gallery.updateSize(true); // reinit Items
    //             };
    //             img.src = item.src ?? '#'; // let's download image
    //         }
    //     });
    //     gallery.init();
    //     console.log('Should have been opened by now.');
    // }

    private closeModal() {
        this.$emit('close');
    }

    private getToken() {
        return ApiService.getToken();
    }

    private getAuthToken() {
        return ApiService.getAuthToken();
    }
}
